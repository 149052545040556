import Tokens from "../../models/Tokens/Tokens";
import UsuarioLoginResponse from "../../models/UserAuth/UsuarioLoginResponse";
import { post } from "./Api";


class TokenService {  
    getAccessToken() {
      return localStorage.getItem("accessToken");
    }
  
    getRefreshToken() {
      return localStorage.getItem("refreshToken");
    }
    
    setAccessToken(token: Object) {
      localStorage.setItem("accessToken", token.toString());
    }

    setRefreshToken(token: Object) {
      localStorage.setItem("refreshToken", token.toString());
    }
  
    removeAccessToken() {
      localStorage.removeItem("accessToken");
    }

    removeRefreshToken() {
      localStorage.removeItem("refreshToken");
    }
  }
  
  export default new TokenService();

  export const refreshTokenService = async (tokens: Tokens) => 
    post<UsuarioLoginResponse>('usuario/refreshlogin/refresh-login', tokens, 
    {
      headers: { 'Content-Type': 'application/json'},
    }).then((res) => {
      const { accessToken, refreshToken } = res.data;
      
      if (accessToken && refreshToken) {
        localStorage.setItem("accessToken", accessToken.toString());
        localStorage.setItem("refreshToken", refreshToken.toString());
      }

      return res.data;

    }).catch((error) =>{
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.href = window.location.protocol + "//" + window.location.host + "/login";
      return Promise.reject(error);
    });