const dev = {
  url: {
    
    API_URL: "https://localhost:44320/api/",
  },
};

const prod = {
  url: {
    API_URL: "https://backapi.siriusmaf.pt/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;

//https://backsiriusapi.mafirol.info/api/
//https://localhost:44320/api/
